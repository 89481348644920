import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	
	username:any;

  constructor(private router: Router) {
  }

  ngOnInit() {
	  
	  this.username=localStorage.getItem('username');
  }


  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }
  
   logout()
  {	  
	  localStorage.clear();
	  this.router.navigate(['/auth/login']);
  }
}
