import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import { ActivatedRoute, Router } from '@angular/router';


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
	
	username:any;
	superadmin=false;
	group=false;
	company=false;	
  
  constructor(private route: ActivatedRoute,private router: Router) {
  }
  
  

  ngOnInit() {
	  
	  //this.username=localStorage.getItem('username');
	  this.username='superadmin';
	  
	  //alert(this.username);
	  if(this.username=='superadmin')
	  {
		 this.superadmin=true; 
	  }
	  else if(this.username=='group')
	  {
		this.group=true;  		 
	  }
	  else if(this.username=='company')
	  {
		this.company=true;  		 
	  }
	  
	  
	  	 
  }
  
  getUrl(val)
  {	  
	  this.router.navigate(['settings/Language'], { queryParams: { val: val } });
  }

}
